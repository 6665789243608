@use "../node_modules/devextreme/scss/widgets/generic/colors" as dx with (
  $color: "light"
);

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url(../node_modules/devextreme/scss/widgets/material/typography/fonts/Roboto-400.ttf) format("truetype");
}

@font-face {
  font-family: "CzIconFont";
  src: url("assets/fonts/czIconFont.ttf") format("truetype");
}

html,
body {
  height: calc(100vh);
  width: calc(100vw);
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

[badge]:after {
  background: red;
  border-radius: 50%;
  color: #fff;
  content: attr(badge);
  font-size: 12px;
  padding: 2px;
  position: absolute;
  text-align: center;
  width: 21px;
  top: 50%;
  margin-top: -11px;
  margin-left: -30px;
  height: 21px;
}

[badge="-"]:after,
[badge="0"]:after,
[badge=""]:after {
  display: none;
}

[subBadge] {
  vertical-align: middle;
}

[subBadge]:before {
  position: absolute;
  display: inline-block;
  background: red;
  border-radius: 50%;
  color: #fff;
  content: attr(subBadge);
  margin-top: 2px;
  font-size: 12px;
  // padding: 2px;
  text-align: center;
  width: 18px;
  height: 18px;
}

[subBadge^="-"]:before,
[subBadge="0"]:before,
[subBadge=""]:before {
  display: none;
}

.content {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.container-header-strong {
  font-size: 16px;
}

.dx-viewport .dx-widget {
  font-size: inherit;
}

.dx-theme-material-typography {
  font-size: 20px;
  font-family: "Roboto";
}

.dx-menu-item {
  color: inherit !important;
}

.dx-menu .dx-menu-item {
  font-size: inherit !important;
}

.dx-layout-manager .dx-field-item {
  font-size: inherit !important;
}

.dx-box-item-content {
  font-size: inherit !important;
}

.dx-texteditor-input {
  font-size: inherit !important;
  padding-right: 9px !important;
}

.color-error {
  color: var(--prime-color-red) !important;
}

.color-success {
  color: var(--prime-color-green) !important;
}

.dx-datagrid-header-panel {
  padding: 0 !important;
}

.dx-datagrid .dx-link {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.btn-round {
  border-radius: 50%;

  .dx-icon {
    color: inherit !important;
    font-size: inherit;
  }
}

.dx-scrollbar-horizontal {
  height: 13px !important;

  .dx-scrollable-scroll {
    height: 13px !important;
  }
}

.dx-scrollbar-vertical {
  width: 13px !important;

  .dx-scrollable-scroll {
    width: 13px !important;
  }
}

.dx-popup-title .dx-closebutton .dx-icon {
  color: var(--prime-color-darkCustom);
}

.dx-menu-item-has-text.dx-state-hover {
  background-color: var(--footer-background-color);
}

.dx-menu .dx-menu-item-expanded {
  background-color: var(--footer-background-color);
}

.dx-context-menu-container-border {
  border: 1px solid var(--header-menu-background-color);
}

.dx-context-menu .dx-submenu {
  background-color: var(--header-menu-background-color);
  border: 1px solid var(--header-menu-background-color);
}

.dx-item.dx-list-item.dx-state-focused.dx-state-active {
  background-color: var(--prime-color-darkCustom);
}

.dx-radiobutton-icon-dot {
  background: var(--prime-color-darkCustom) !important;
}

.dx-link-icon {
  font-size: 23px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.dx-toolbar-items-container {
  color: var(--prime-color-darkCustom);
}

.dx-datagrid-search-panel {
  margin-left: 0 !important;
}

.dx-accordion-item-title::before {
  margin-top: 5px !important;
  margin-left: 0 !important;
  color: var(--prime-color-darkCustom) !important;
}

.status-select-icon {
  font-size: 20px;
}

.status-select-icon-default {
  color: var(--prime-color-black);
}

.status-select-icon-none {
  color: #00000000;
}

.status-select-icon-offen {
  color: rgb(159, 90, 43);
}

.status-select-icon-inbearbeitung {
  color: orange;
}

.status-select-icon-inwarteliste {
  color: rgb(160, 110, 210);
}

.status-select-icon-vorgeschlagen {
  color: rgb(29, 178, 245);
}

.status-select-icon-angenommen {
  color: var(--prime-color-green);
}

.status-select-icon-abgelehnt {
  color: var(--prime-color-red);
}
.status-select-icon-vorschlagangenommen {
  color: rgb(16, 10, 181);
}
.status-select-icon-vorschlagabgelehnt {
  color: rgb(246, 9, 234);
}

[hidden] {
  display: none !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines > td {
  color: var(--button-menue-font-color-custom);
  background-color: var(--prime-color-darkCustom);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.dxc-labels {
  pointer-events: all;
}

.dx-overlay-wrapper {
  width: 100% !important;
}

// --- dx-buttons --- NORMAL
.dx-button.dx-button-normal {
  background-color: var(--button-normal-color);
  color: var(--prime-color-white);

  .dx-icon {
    color: var(--prime-color-darkCustom);
  }
}

.dx-button-has-icon {
  .dx-icon {
    font-size: 18px !important;
  }
}

.dx-button.dx-button-normal.dx-state-hover {
  background-color: var(--button-normal-background-color) !important;
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-normal.dx-state-active {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-normal.dx-state-focused {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-normal.dx-state-disabled {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  .dx-button-text {
    color: var(--button-normal-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-normal.dx-state-disabled {
  .dx-button-text {
    color: var(--button-normal-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-normal.dx-state-disabled {
  .dx-button-text {
    color: var(--button-normal-background-color);
  }
}

.dx-button-mode-outlined.dx-button-normal {
  background-color: transparent;
  border-color: var(--button-normal-color);
  color: var(--button-normal-color);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-normal {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-normal-color);
}

.dx-button-mode-text.dx-button-normal.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-normal.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-normal.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- SUCCESS

.dx-button.dx-button-success {
  background-color: var(--button-success-background-color);
  color: var(--button-success-color);

  .dx-icon {
    color: var(--button-success-color);
  }
}

.dx-button.dx-button-success.dx-state-hover {
  background-color: var(--button-success-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-success.dx-state-active {
  background-color: var(--button-success-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-success.dx-state-focused {
  background-color: var(--button-success-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-success.dx-state-disabled {
  background-color: var(--button-success-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  .dx-button-text {
    color: var(--button-success-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-success.dx-state-disabled {
  .dx-button-text {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-success.dx-state-disabled {
  .dx-button-text {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-outlined.dx-button-success {
  background-color: transparent;
  border-color: var(--button-success-background-color);
  color: var(--button-success-background-color);

  .dx-icon {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-outlined.dx-button-success.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-success.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-success.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-success {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-success-background-color);

  .dx-icon {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-text.dx-button-success.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-success.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-success.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- DEFAULT

.dx-button.dx-button-default {
  background-color: var(--button-default-background-color);
  color: var(--button-default-color);

  .dx-icon {
    color: var(--button-default-color);
  }
}

.dx-button.dx-button-default.dx-state-hover {
  background-color: var(--button-default-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-default.dx-state-active {
  background-color: var(--button-default-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
  color: var(--button-default-color);
}

.dx-button.dx-button-default.dx-state-focused {
  background-color: var(--button-default-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-outlined.dx-button-default {
  background-color: transparent;
  border-color: var(--button-default-background-color);
  color: var(--button-default-background-color);

  .dx-icon {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-contained.dx-button.dx-button-default.dx-state-disabled {
  background-color: var(--button-default-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  .dx-button-text {
    color: var(--button-default-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-default.dx-state-disabled {
  .dx-button-text {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-default.dx-state-disabled {
  .dx-button-text {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-outlined.dx-button-default.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-default.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-default.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-default {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-default-background-color);

  .dx-icon {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-text.dx-button-default.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-default.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-default.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- DANGER

.dx-button.dx-button-danger {
  background-color: var(--button-danger-background-color);
  color: var(--button-danger-color);

  .dx-icon {
    color: var(--button-danger-color);
  }
}

.dx-button.dx-button-danger.dx-state-hover {
  background-color: var(--button-danger-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-danger.dx-state-active {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-danger.dx-state-focused {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-danger.dx-state-disabled {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  .dx-button-text {
    color: var(--button-danger-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-danger.dx-state-disabled {
  .dx-button-text {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-danger.dx-state-disabled {
  .dx-button-text {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-outlined.dx-button-danger {
  background-color: transparent;
  border-color: var(--button-danger-background-color);
  color: var(--button-danger-background-color);

  .dx-icon {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-outlined.dx-button-danger.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-danger.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-danger.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-danger {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-danger-background-color);

  .dx-icon {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-text.dx-button-danger.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-danger.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-danger.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
  opacity: 0.5;
}

.dx-button-text {
  text-transform: none;
  font-size: medium !important;
}

// ---- Kalender ----
.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 0;
  box-shadow: none;
}

.dx-calendar-caption-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--prime-color-darkCustom) !important;
}

.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
  color: var(--button-menue-font-color-custom);
  border-radius: 25% !important;
  -webkit-box-shadow:
    inset 0 0 0 1px var(--prime-color-darkCustom),
    inset 0 0 0 1000px var(--prime-color-darkCustom);
  box-shadow:
    inset 0 0 0 1px var(--prime-color-darkCustom),
    inset 0 0 0 1000px var(--prime-color-darkCustom);
}

.dx-calendar-cell.dx-calendar-today,
.dx-calendar-cell.dx-calendar-today.dx-calendar-contoured-date {
  color: var(--prime-color-darkCustom);
  border-radius: 25% !important;
  -webkit-box-shadow:
    inset 0 0 0 1px var(--prime-color-darkGray),
    inset 0 0 0 1000px var(--prime-color-darkGray);
  box-shadow:
    inset 0 0 0 1px var(--prime-color-darkGray),
    inset 0 0 0 1000px var(--prime-color-darkGray);
}

.dx-calendar-navigator-previous-view,
.dx-calendar-navigator-next-view {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--prime-color-darkCustom) !important;
}

.dx-calendar-navigator-previous-view {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.dx-calendar-navigator-next-view {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.dx-datebox-calendar.dx-dropdowneditor .dx-dropdowneditor-icon::before,
.dx-datebox-calendar.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
  content: "";
}

.dx-calendar-navigator {
  background-color: var(--prime-color-darkCustom);

  .dx-button-text {
    color: var(--button-menue-font-color-custom);
  }

  .dx-icon {
    color: var(--button-menue-font-color-custom) !important;
  }
}

#docXToolbarButton {
  margin-right: 8px !important;
}

#docXToolbarButtonRight {
  margin-right: 8px !important;

  .dx-icon {
    float: right;
    padding-left: 10px;
    margin-right: 0px;
  }
}

// --------------------

.shift {
  ::ng-deep .dx-state-hover {
    cursor: copy !important;
  }
}

//-- dx-icons durch unsere ersetzen
.dx-icon-edit::before {
  font-family: "CzIconFont";
  content: "\e91a";
}

.dx-icon-trash::before {
  font-family: "CzIconFont";
  content: "\e91c";
}

.dx-icon-add::before {
  font-family: "CzIconFont";
  content: "\e900";
}

.dx-icon-help::before {
  font-family: "CzIconFont";
  content: "\e910";
}

.dx-icon-preferences::before {
  font-family: "CzIconFont";
  content: "\e919";
}

.dx-icon-find::before {
  font-family: "CzIconFont";
  content: "\e91e";
}

.dx-icon-clock::before {
  font-family: "CzIconFont";
  content: "\e92b";
}

.dx-icon-home::before {
  font-family: "CzIconFont";
  content: "\e911";
}

.dx-icon-export::before {
  font-family: "CzIconFont";
  content: "\e90c";
}

.dx-icon-event::before {
  font-family: "CzIconFont";
  content: "\e908";
}

.dx-icon-user::before {
  font-family: "CzIconFont";
  content: "\e905";
}

.dx-icon-contentlayout::before {
  font-family: "CzIconFont";
  content: "\e916";
}

.dx-icon-clear::before {
  font-family: "CzIconFont";
  content: "\e903";
}

.dx-icon-activefolder::before {
  font-family: "CzIconFont";
  content: "\e90f";
}

.dx-icon-download::before {
  font-family: "CzIconFont";
  content: "\e90a";
}

.dx-icon-check::before {
  font-family: "CzIconFont";
  content: "\e918"; /*918 904*/
}

.dx-icon-remove::before {
  font-family: "CzIconFont";
  content: "\e903"; /*903 924*/
}

.dx-icon-columnchooser::before {
  font-family: "CzIconFont";
  content: "\e928";
}

.dx-icon-docxfile::before {
  font-family: "CzIconFont";
  content: "\e923";
}

.dx-icon-close::before {
  font-family: "CzIconFont";
  content: "\e903"; /*903 924*/
}

.dx-icon-save::before {
  font-family: "CzIconFont";
  content: "\e904"; /*918 904*/
}

// NEU
.dx-icon-verticalaligntop::before {
  font-family: "CzIconFont";
  content: "\e935";
}

.dx-icon-chart::before {
  font-family: "CzIconFont";
  content: "\e92e";
}

.dx-icon-doc::before {
  font-family: "CzIconFont";
  content: "\e92f";
}

.dx-icon-formula::before {
  font-family: "CzIconFont";
  content: "\e92d";
}

.dx-icon-card::before {
  font-family: "CzIconFont";
  content: "\e905";
}

.dx-icon-food::before {
  font-family: "CzIconFont";
  content: "\e930";
}

.dx-icon-info::before {
  font-family: "CzIconFont";
  content: "\e932";
}

.dx-icon-message::before {
  font-family: "CzIconFont";
  content: "\e913";
}

.dx-icon-calculator::before {
  font-family: "CzIconFont";
  content: "\e92d";
}

.dx-icon-file::before {
  font-family: "CzIconFont";
  content: "\e92f";
}

.dx-icon-exportselected::before {
  font-family: "CzIconFont";
  content: "\e91d";
}

.dx-icon-revert::before {
  font-family: "CzIconFont";
  content: "\e920";
}

.dx-icon-excel::before {
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e931";
}

.dx-icon-rename::before {
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e937";
}

.dx-icon-chevronright::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e927";
  transform: translateX(-25%) rotate(270deg) scaleX(0.8);
}
.dx-icon-chevronbottom::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e927";
  transform: translateY(-25%) scaleX(0.8);
}
.dx-icon-chevronleft::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e927";
  transform: translateX(25%) rotate(90deg) scaleX(0.8);
}
.dx-icon-chevrontop::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e927";
  transform: translateY(25%) rotate(180deg) scaleX(0.8);
}

.dx-icon-chevrondoubleright::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e936";
  transform: translateX(-25%) rotate(270deg) scaleX(0.8);
}
.dx-icon-chevrondoublebottom::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e936";
  transform: translateY(-25%) scaleX(0.8);
}
.dx-icon-chevrondoubleleft::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e936";
  transform: translateX(25%) rotate(90deg) scaleX(0.8);
}
.dx-icon-chevrondoubletop::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e936";
  transform: translateY(25%) rotate(180deg) scaleX(0.8);
}

.dx-icon-sortuptext::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e93b";
}

.dx-icon-sortdowntext::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e93a";
}
.dx-icon-undo::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e939";
}

.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e93a";
}

.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc::before {
  display: inline-block;
  font-family: "CzIconFont";
  font-style: normal;
  content: "\e93b";
}

.dx-icon-card::before{
  font-family: 'CzIconFont' !important;
  content: '\e93c';
}

/* TODO Kallender Icon in der Datumsbox   Uhrzeit in stammdaten genau so und die Pfeile bei den selectboxen vielleicht noch
::ng-deep.dx-datebox-calender .dx-dropdowneditor-icon::before{
    color: red!important;
    font-family: "CzIconFont";
    content: "\e908";
}*/
