@font-face {
  font-family: "CzIconFont";
  src: url("./assets/fonts/czIconFont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: "CzIconFont" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  aspect-ratio: 1;

  //   /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.add:before {
    content: "\e900";
  }
  &.add_contact:before {
    content: "\e901";
  }
  &.arrow_right:before {
    content: "\e902";
  }
  &.cancel:before {
    content: "\e903";
  }
  &.check:before {
    content: "\e904";
  }
  &.client:before {
    content: "\e905";
  }
  &.clients:before {
    content: "\e906";
  }
  &.crucifix:before {
    content: "\e907";
  }
  &.date:before {
    content: "\e908";
  }
  &.divers:before {
    content: "\e909";
  }
  &.download:before {
    content: "\e90a";
  }
  &.edit:before {
    content: "\e90b";
  }
  &.exit:before {
    content: "\e90c";
  }
  &.export:before {
    content: "\e90d";
  }
  &.female:before {
    content: "\e90e";
  }
  &.folder:before {
    content: "\e90f";
  }
  &.help:before {
    content: "\e910";
  }
  &.home:before {
    content: "\e911";
  }
  &.import:before {
    content: "\e912";
  }
  &.mail:before {
    content: "\e913";
  }
  &.male:before {
    content: "\e914";
  }
  &.maximize:before {
    content: "\e915";
  }
  &.menu:before {
    content: "\e916";
  }
  &.minimize:before {
    content: "\e917";
  }
  &.ok:before {
    content: "\e918";
  }
  &.option:before {
    content: "\e919";
  }
  &.pencil:before {
    content: "\e91a";
  }
  &.phone:before {
    content: "\e91b";
  }
  &.remove:before {
    content: "\e91c";
  }
  &.save:before {
    content: "\e91d";
  }
  &.search:before {
    content: "\e91e";
  }
  &.shrink:before {
    content: "\e91f";
  }
  &.sync:before {
    content: "\e920";
  }
  &.unknown_gender:before {
    content: "\e921";
  }
  &.upload:before {
    content: "\e922";
  }
  &.word:before {
    content: "\e923";
  }
  &.x:before {
    content: "\e924";
  }
  &.circle:before {
    content: "\e925";
  }
  &.sandglass:before {
    content: "\e926";
  }
  &.chevron_bottom:before {
    content: "\e927";
  }
  &.column_chooser:before {
    content: "\e928";
  }
  &.exclamation_mark:before {
    content: "\e929";
  }
  &.pin:before {
    content: "\e92a";
  }
  &.resubmissions:before {
    content: "\e92b";
  }
  // NEU
  .icon-checklist:before {
    content: "\e92c";
  }
  .icon-calculator:before {
    content: "\e92d";
  }
  .icon-diagram:before {
    content: "\e92e";
  }
  .icon-document:before {
    content: "\e92f";
  }
  .icon-food:before {
    content: "\e930";
  }
  .icon-excel:before {
    content: "\e931";
  }
  .icon-information:before {
    content: "\e932";
  }
  .icon-list:before {
    content: "\e933";
  }
  .icon-pdf:before {
    content: "\e934";
  }
  .icon-inquire:before {
    content: "\e935";
  }
  .icon-revert:before {
    content: "\e920";
  }

  &.arrow_left:before {
    display: inline-block;
    transform: rotate(180deg);
    content: "\e902";
  }
  &.arrow_top:before {
    display: inline-block;
    transform: rotate(270deg);
    content: "\e902";
  }
  &.arrow_bottom:before {
    display: inline-block;
    transform: rotate(90deg);
    content: "\e902";
  }

  &.chevron_top:before {
    display: inline-block;
    transform: translateY(25%) rotate(180deg) scaleX(0.8);
    font-weight: 700;
    content: "\e927";
  }
  &.chevron_right:before {
    display: inline-block;
    transform: translateX(-25%) rotate(270deg) scaleX(0.8);
    font-weight: 700;
    content: "\e927";
  }
  &.chevron_left:before {
    display: inline-block;
    transform: translateX(25%) rotate(90deg) scaleX(0.8);
    font-weight: 700;
    transform: scaleX(0.8);
    content: "\e927";
  }
  &.chevron_bottom:before {
    display: inline-block;
    transform: translateY(-25%) scaleX(0.8);
    font-weight: 700;
    content: "\e927";
  }

  &.double_chevron:before {
    content: "\e936";
  }
  &.rename:before {
    content: "\e937";
  }
  
  &.passwort_vergessen:before {
    content: "\e938";
  }
  &.revert:before {
    content: "\e939";
  }
  &.redo:before {
    transform: scaleX(-1);
    content: "\e939";
  }
  &.sortierung_az:before {
    content: "\e93a";
  }
  &.sortierung_za:before {
    content: "\e93b";
  }
  &.clientfilled:before {
    content: "\e93c";
  }
}
